import React from 'react'
import { Button, Col, Form, Row, Select, Input, DatePicker } from 'antd'
import ReactInputMask from 'react-input-mask'
// Local
import {
  configSelects,
  DiagnoseConfigsSelects,
  tagSelector,
} from '@/configs/diagnose.configs'
import { maskPhone } from '@/constants'
import { rules } from '@/helpers/rules'
// Local Dir
import styles from './styles.module.scss'
import { StepInfoFormFields, type StepInfoProps } from './types'
import { ClientIllnessRows } from './components'
import TextArea from 'antd/lib/input/TextArea'
import CustomDatePicker from '../core/Datepick/Datepick'

export const StepInfo: React.FC<StepInfoProps> = ({
  loading,
  initialValues,
  onSubmit,
  isChange = false,
  onValuesChange,
  onChangeAdditionalData,
  additionalFormData,
  bottomContent,
  hideIllness,
  isAdmin,
}: StepInfoProps) => {
  const [form] = Form.useForm<StepInfoFormFields>()

  const renderSelect = (item: DiagnoseConfigsSelects) => {
    const result = []

    result.push(
      <Col
        key={item.key}
        span={item.span ? item.span : 12}
        className={styles.stepInfo__input}
        style={item.style}
      >
        <Form.Item name={item.key} labelCol={{ span: 24 }} label={item.title}>
          <Select className={styles.stepInfo__select}>
            {(item.data as { value: number; text: string }[]).map(
              (option, idx) => (
                <Select.Option key={idx} value={option.value}>
                  {option.text}
                </Select.Option>
              )
            )}
          </Select>
        </Form.Item>
      </Col>
    )

    if (
      item.additionalField &&
      item.additionalField.targetValue === form.getFieldValue(item.key)
    ) {
      result.push(
        <Col
          key={item.additionalField.key}
          span={item.span ? item.span : 12}
          className={styles.stepInfo__input}
          style={item.style}
        >
          <Form.Item
            name={item.additionalField.key}
            labelCol={{ span: 24 }}
            label={item.additionalField.title}
          >
            <TextArea />
          </Form.Item>
        </Col>
      )
    }

    return result
  }
  const _renderSelects = () => {
    return configSelects.map((item) => item.data && renderSelect(item))
  }

  return (
    <>
      <Form
        form={form}
        name="createClientStep1"
        className={styles.stepInfo}
        onFinish={onSubmit}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        <div className={styles.stepInfo__content}>
          <Row justify="space-between">
            <Col span={12} className={styles.stepInfo__input}>
              {additionalFormData && additionalFormData.name ? (
                <div className={'ant-input'}>
                  <input
                    className={styles.full_name_input}
                    value={additionalFormData.name}
                    onChange={(e) => onChangeAdditionalData(e.target.value)}
                  />
                </div>
              ) : (
                <Form.Item name="name" rules={rules.fullName}>
                  <Input placeholder="ПІБ" />
                </Form.Item>
              )}
            </Col>

            {isAdmin ? (
              <>
                <Col span={12} className={styles.stepInfo__input}>
                  <Form.Item name="email" rules={rules.email}>
                    <Input placeholder="Електронна пошта" />
                  </Form.Item>
                </Col>
                <Col span={12} className={styles.stepInfo__input}>
                  <Form.Item name="phoneNumber" rules={rules.phoneNumber}>
                    <ReactInputMask
                      mask={maskPhone}
                      placeholder="Номер телефону"
                    >
                      {(inputProps) => <Input {...inputProps} />}
                    </ReactInputMask>
                  </Form.Item>
                </Col>
              </>
            ) : null}

            <Col span={12} className={styles.stepInfo__input}>
              <Form.Item name="birthDate" rules={rules.birthDayRequired}>
                <CustomDatePicker
                  placeholder="Дата народження"
                  disabledDate={(d) =>
                    !d ||
                    d.isAfter(new Date()) ||
                    d.isSameOrBefore('1930-01-01')
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            {_renderSelects()}
            <Col span={24}>
              <Form.Item
                name={tagSelector.key}
                labelCol={{ span: 24 }}
                label={tagSelector.title}
              >
                <Select
                  placeholder="Оберіть типи лікування"
                  mode="multiple"
                  style={{ width: '100%' }}
                >
                  {tagSelector.data.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {hideIllness ? null : (
              <Col span={24}>
                <ClientIllnessRows />
              </Col>
            )}
            {isAdmin ? (
              <Col span={24}>
                <Form.Item
                  name="instagramLink"
                  labelCol={{ span: 24 }}
                  label="Instagram"
                >
                  <Input placeholder="@instagram-example" />
                </Form.Item>
              </Col>
            ) : null}
            <Col span={24}>
              <Form.Item name="note" labelCol={{ span: 24 }} label="Примітки">
                <Input.TextArea
                  placeholder="Поле для введення"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        {bottomContent}
        <div className={styles.stepInfo__actions}>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            className={styles.stepInfo__action}
          >
            Зберегти
          </Button>
        </div>
      </Form>
    </>
  )
}
